import { useEffect, useMemo } from 'react';
import { Box, Stack } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';

import { LocalizationTypography } from './localization';
import { PaymentForm } from './PaymentForm';

import { TRACKING_PAGES } from '~common';
import { useEventTrackingContext, useMainContext } from '~providers';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY ?? '');

export const StepThree = () => {
  const { recordPageVisited } = useEventTrackingContext();
  const { preAuthAmount } = useMainContext();
  const options: StripeElementsOptions = useMemo(
    () => ({
      appearance: {
        theme: 'stripe',
      },
    }),
    [],
  );

  useEffect(() => {
    recordPageVisited(TRACKING_PAGES.SELECT_PAYMENT_METHOD);
  }, [recordPageVisited]);

  const onClickViewTerms = () => {
    window?.open(
      process.env.REACT_APP_VIEW_TERMS_URL ??
        'https://www.chargefuze.com/terms-of-service/',
      '_blank',
    );
  };

  return (
    <>
      <Stack display="grid" sx={{ gridRowGap: 16 }}>
        <LocalizationTypography
          variant="caption"
          fontWeight={500}
          color="#6A737D"
          textId="app.rentalPage.stepThree.step"
          defaultText="STEP 3 OF 3"
        />
        <LocalizationTypography
          variant="h5"
          fontWeight={600}
          textId="app.rentalPage.stepThree.choosePay"
          defaultText="Choose how to pay"
        />
        <LocalizationTypography
          variant="body1"
          fontWeight={400}
          color="#6A737D"
          textId="app.rentalPage.stepThree.paymentDetails"
          textValues={{ value: preAuthAmount }}
          defaultText={`Your card will be pre-authorized for ${preAuthAmount}. This is a temporary hold and
          will be reversed within 5 business days.`}
        >
          <Box
            component="span"
            marginLeft={'0.3rem'}
            sx={{
              color: 'primary.main',
              fontWeight: 400,
              fontSize: '1rem',
              cursor: 'pointer',
            }}
            onClick={onClickViewTerms}
          >
            <LocalizationTypography
              disableParentTypography
              textId="app.rentalPage.stepThree.viewTerms"
              defaultText="View terms"
            />
          </Box>
        </LocalizationTypography>
      </Stack>
      <Stack display="grid" sx={{ gridRowGap: 16 }}>
        <LocalizationTypography
          variant="body1"
          fontWeight={600}
          color="#009EFA"
          textId="app.rentalPage.stepThree.textRentalDetails"
          defaultText="Rental Details"
        />
        <Stack direction="row" justifyContent="space-between">
          <LocalizationTypography
            variant="body1"
            fontWeight={400}
            color="#6A737D"
            textId="app.rentalPage.stepThree.text1xCharger"
            defaultText="1x Charger"
          />
          <LocalizationTypography
            variant="body1"
            fontWeight={400}
            color="#6A737D"
            textId="app.rentalPage.stepThree.textPricingOnScreen"
            defaultText="Pricing on screen"
          />
        </Stack>
      </Stack>
      <Elements stripe={stripePromise} options={options}>
        <PaymentForm />
      </Elements>
    </>
  );
};
