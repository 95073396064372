import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

import useSyncMutation from './useSyncMutation';

import { useLanguageContext } from '~providers';
import { postRequest } from '~services';
import { BatteryRequest } from '~types';

export const useMutationRequestBattery = () => {
  const { lt } = useLanguageContext();

  return useSyncMutation(
    (batteryRequest: BatteryRequest) => {
      return postRequest({
        path: 'api/rental/request-battery-web',
        data: batteryRequest,
      });
    },
    {
      onError(error) {
        if (error instanceof AxiosError) {
          let message = '';
          if (error.response?.data?.message.includes('card_declined')) {
            message = lt(
              'text.hooks.useMutationRequestBattery.error',
              'Your card was declined',
            );
          } else {
            message =
              error?.response?.data?.message ??
              lt(
                'text.errorRequestBattery',
                'Somethings went wrong when request battery',
              );
          }
          toast.error(message);
        } else {
          toast.error(
            lt(
              'text.errorRequestBattery',
              'Somethings went wrong when request battery',
            ),
          );
        }
      },
    },
  );
};
