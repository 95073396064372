import { useCallback, useRef, useState } from 'react';
import {
  NavigateOptions,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import Reaptcha from 'reaptcha';

export const useCaptcha = () => {
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [captchaToken, setCaptchaToken] = useState<string | undefined>(
    undefined,
  );

  const onCaptchaVerified = useCallback((token: string) => {
    setCaptchaVerified(true);
    setCaptchaToken(token);
  }, []);

  const onCaptchaExpired = () => {
    setCaptchaToken(undefined);
    setCaptchaVerified(false);
  };

  const captchaRef = useRef<Reaptcha | null>(null);

  return {
    captchaVerified,
    onCaptchaVerified,
    onCaptchaExpired,
    setCaptchaVerified,
    captchaRef,
    captchaToken,
    setCaptchaToken,
  };
};

export const useNavigateInApp = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  return (path: string, options?: NavigateOptions) =>
    navigate(
      {
        pathname: path,
        search: `${searchParams.toString()}`,
      },
      options,
    );
};

export { useEjectBattery } from './useEjectBattery';
export { useMutationRedeemReward } from './useMutationRedeemReward';
export { useMutationRequestBattery } from './useMutationRequestBattery';
export { useMutationSavePaymentInfo } from './useMutationSavePaymentInfo';
export { useQueryGetPaymentInfo } from './useQueryGetPaymentInfo';
export { useQueryGetRentalInfo } from './useQueryGetRentalInfo';
export { useRedeemReward } from './useRedeemReward';
