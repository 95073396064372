import { useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Stack } from '@mui/material';

import { TRACKING_PAGES } from '~common';
import { LocalizationTypography } from '~components/organisms';
import { useNavigateInApp } from '~hooks';
import { useEventTrackingContext } from '~providers';
import { handleOpenApp } from '~utils';

export const HomePage = () => {
  const navigate = useNavigateInApp();
  const { recordPageVisited } = useEventTrackingContext();

  useEffect(() => {
    recordPageVisited(TRACKING_PAGES.HOME);
  }, [recordPageVisited]);

  const navigateToRentalPage = () => {
    navigate('/fuzebox');
  };

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      flex={1}
      sx={{
        background: 'url(/images/background.png)',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        px: 5.5,
        py: 4,
        width: '100%',
        height: '100%',
        color: 'white',
        textAlign: 'center',
      }}
    >
      <Stack sx={{ pt: 0, px: 4, pb: 4 }} alignItems="center">
        <img src="/images/logo.svg" alt="logo" width={'142'} />
      </Stack>
      <Stack display="grid" sx={{ gridRowGap: 56 }}>
        <LocalizationTypography
          variant="h4"
          fontWeight={600}
          textId="app.homePage.header"
          defaultText="Charge any device, anywhere, anytime!"
        />
        <Stack>
          <LocalizationTypography
            variant="body1"
            fontWeight={500}
            textId="app.homePage.subheader"
            defaultText="Rent and carry a portable FUZE charger to power your devices on the go."
          />
          <LocalizationTypography
            variant="body1"
            fontWeight={500}
            textId="app.homePage.subsubheader"
            defaultText="Simply return it to any chargeFUZE station when you’re finished."
          />
        </Stack>

        <Stack width="100%" display="grid" sx={{ gridRowGap: 24 }}>
          <Button
            variant="contained"
            fullWidth
            sx={{
              bgcolor: 'white !important',
              color: '#009EFA',
              py: '18px',
              borderRadius: '50px',
            }}
            onClick={navigateToRentalPage}
          >
            <LocalizationTypography
              variant="body1"
              fontWeight={500}
              textId="app.homePage.buttonRentCharger"
              defaultText="Rent a FUZE charger"
            />
          </Button>
          <Button
            variant="outlined"
            fullWidth
            sx={{
              border: '1px solid white',
              bgcolor: 'transparent !important',
              color: 'white',
              py: '18px',
              borderRadius: '50px',
              '&:hover': {
                border: '1px solid white',
                bgcolor: 'transparent',
                color: 'white',
              },
            }}
            startIcon={<AddIcon />}
            onClick={handleOpenApp}
          >
            <LocalizationTypography
              variant="body1"
              fontWeight={500}
              textId="app.homePage.buttonDownloadApp"
              defaultText="Download chargeFUZE app"
            />
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};
