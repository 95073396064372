import { PhoneNumberUtil } from 'google-libphonenumber';
import { head, reduce, tail } from 'lodash';

import { DEFAULT_COUNTRY_DATA } from '~common';
import { allCountries, guessSelectedCountry } from '~configs';
import { TPhoneNumberCountryData } from '~types';

export const isValidPhoneNumber = (
  rawPhoneNumber: string,
  countryData?: TPhoneNumberCountryData,
) => {
  try {
    const phoneUtil = PhoneNumberUtil.getInstance();
    const number = phoneUtil.parseAndKeepRawInput(
      rawPhoneNumber,
      countryData?.countryCode,
    );

    return phoneUtil.isValidNumber(number);
  } catch (e) {
    return false;
  }
};

export const parseRegionCodeFromRawPhoneNumber = (rawPhoneNumber: string) => {
  const phoneUtil = PhoneNumberUtil.getInstance();
  let regionCode = null;

  const parsedPhoneNumber = phoneUtil.parseAndKeepRawInput(rawPhoneNumber);
  const countryCode = parsedPhoneNumber.getCountryCode();

  if (countryCode) {
    regionCode = phoneUtil.getRegionCodeForCountryCode(countryCode);
  }

  return regionCode;
};

export const parseCountryCodeFromRawPhoneNumber = (rawPhoneNumber: string) => {
  const phoneUtil = PhoneNumberUtil.getInstance();

  const parsedPhoneNumber = phoneUtil.parseAndKeepRawInput(rawPhoneNumber);
  return parsedPhoneNumber.getCountryCode();
};

export const formatNumber = (
  text: string | undefined,
  disableCountryCode = false,
  enableLongNumbers = false,
  autoFormat = true,
) => {
  if (!text || text.length === 0) {
    return '';
  }

  const country = guessSelectedCountry(
    text.replace(/\D/g, '').substring(0, 6),
    allCountries,
    DEFAULT_COUNTRY_DATA.countryCode,
  );

  const patternArg = country.format;

  let pattern;
  if (disableCountryCode && patternArg) {
    pattern = patternArg.split(' ');
    pattern.shift();
    pattern = pattern.join(' ');
  } else {
    pattern = patternArg;
  }

  // for all strings with length less than 3, just return it (1, 2 etc.)
  // also return the same text if the selected country has no fixed format
  if ((text && text.length < 2) || !pattern || !autoFormat) {
    return disableCountryCode ? text : `+${text}`;
  }

  const formattedObject = reduce(
    pattern,
    (acc, character) => {
      if (acc.remainingText.length === 0) {
        return acc;
      }

      if (character !== '.') {
        return {
          formattedText: acc.formattedText + character,
          remainingText: acc.remainingText,
        };
      }

      return {
        formattedText: acc.formattedText + head(acc.remainingText),
        remainingText: tail(acc.remainingText),
      };
    },
    {
      formattedText: '',
      remainingText: text.split(''),
    },
  );

  let formattedNumber;
  if (enableLongNumbers) {
    formattedNumber =
      formattedObject.formattedText + formattedObject.remainingText.join('');
  } else {
    formattedNumber = formattedObject.formattedText;
  }
  // Always close brackets
  if (formattedNumber.includes('(') && !formattedNumber.includes(')'))
    formattedNumber += ')';
  return formattedNumber;
};
