import { common } from '@mui/material/colors';
import { alpha, Palette } from '@mui/material/styles';

import { error, indigo, info, neutral, success, warning } from './colors';

export const createPalette = (): Omit<
  Palette,
  | 'contrastThreshold'
  | 'common'
  | 'getContrastText'
  | 'grey'
  | 'secondary'
  | 'tonalOffset'
  | 'type'
  | 'augmentColor'
> => {
  return {
    action: {
      active: neutral[500],
      disabled: alpha(neutral[900], 0.38),
      disabledBackground: alpha(neutral[900], 0.12),
      focus: alpha(neutral[900], 0.16),
      hover: alpha(neutral[900], 0.04),
      selected: alpha(neutral[900], 0.12),
      hoverOpacity: 0.04,
      selectedOpacity: 0.3,
      disabledOpacity: 0.38,
      focusOpacity: 0.3,
      activatedOpacity: 1,
    },
    background: {
      default: common.white,
      paper: common.white,
    },
    divider: '#F2F4F7',
    error,
    info,
    mode: 'light',
    neutral,
    primary: indigo,
    success,
    text: {
      primary: neutral[900],
      secondary: neutral[500],
      disabled: alpha(neutral[900], 0.38),
    },
    warning,
  };
};
