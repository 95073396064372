import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3';

import { useMainContext } from '~providers';

export const Captcha: React.FC = () => {
  const { onCaptchaVerified, refreshCaptcha } = useMainContext();

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITEKEY ?? ''}
      useEnterprise={true}
    >
      <GoogleReCaptcha
        onVerify={onCaptchaVerified}
        refreshReCaptcha={refreshCaptcha}
      />
    </GoogleReCaptchaProvider>
  );
};
