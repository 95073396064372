import { useEffect, useState } from 'react';
import { useMount } from 'react-use';
import { Box, Checkbox, Stack } from '@mui/material';

import { LocalizationTypography } from './localization';

import { TRACKING_BUTTON, TRACKING_PAGES } from '~common';
import { MainButton, PhoneInput } from '~components/atoms';
import { useEventTrackingContext, useMainContext } from '~providers';

export const StepOne = () => {
  const {
    phoneNumber,
    setPhoneNumber,
    isLoadingOTP,

    captchaVerified,
    agreePolicy,
    onAgreePolicy,
    handleSubmitGetOTP,
  } = useMainContext();
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState<boolean | null>(
    null,
  );
  const { recordPageVisited, recordBtnClicked } = useEventTrackingContext();

  useEffect(() => {
    recordPageVisited(TRACKING_PAGES.ENTER_PHONE_NUMBER);
  }, [recordPageVisited]);

  const submit = () => {
    if (!isValidPhoneNumber || !captchaVerified) return;
    handleSubmitGetOTP();
    recordBtnClicked(TRACKING_BUTTON.SEND_VERIFICATION_CODE);
  };

  useMount(() => {
    setPhoneNumber('+1');
  });

  return (
    <>
      <Stack display="grid" sx={{ gridGap: 16, gridRowGap: 16 }}>
        <LocalizationTypography
          variant="caption"
          fontWeight={500}
          color={'#6A737D'}
          textId="app.rentalPage.stepOne.step"
          defaultText="STEP 1 OF 3"
        />
        <LocalizationTypography
          variant="h5"
          fontWeight={600}
          textId="app.rentalPage.stepOne.enterPhoneNo"
          defaultText="Enter phone number"
        />
        <LocalizationTypography
          variant="body1"
          fontWeight={400}
          color="#6A737D"
          textId="app.rentalPage.stepOne.verifyNumber"
          defaultText="We’ll text you a code to verify your number."
        />
      </Stack>
      <Stack display="grid" sx={{ gridGap: 16, gridRowGap: 16 }}>
        <PhoneInput
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          isValid={isValidPhoneNumber}
          setIsValid={setIsValidPhoneNumber}
        />

        <Stack direction={'row'} gap={1} alignItems={'flex-start'}>
          <Checkbox
            checked={agreePolicy}
            onChange={onAgreePolicy}
            size="small"
          />
          <LocalizationTypography
            variant="caption"
            fontWeight={400}
            color="#6A737D"
            lineHeight={'1rem'}
            textId="app.rentalPage.stepOne.submissionMessage"
            components={{
              tag1: (
                <Box
                  component="a"
                  href="https://www.chargefuze.com/terms-of-service"
                  sx={{
                    textDecoration: 'unset',
                    color: '#009EFA',
                    fontWeight: '450',
                  }}
                />
              ),
              tag2: (
                <Box
                  component="a"
                  href="https://www.chargefuze.com/privacy-policy"
                  sx={{
                    textDecoration: 'unset',
                    color: '#009EFA',
                    fontWeight: '450',
                  }}
                />
              ),
            }}
            defaultText="By submitting your phone number, you confirm that you have read and agreed to chargeFUZE's <tag1>Terms of Service</tag1> and <tag2>Privacy Policy</tag2>, and you consent to receive text messages from chargeFUZE for direct marketing purposes. Read our Privacy Policy to learn more about our data practices and opting-out of text communications. Msg & data rates may apply."
          />
        </Stack>
        <MainButton
          disabled={
            !isValidPhoneNumber ||
            !captchaVerified ||
            isLoadingOTP ||
            !agreePolicy
          }
          variant="contained"
          onClick={submit}
          isLoading={isLoadingOTP}
        >
          <LocalizationTypography
            variant="body1"
            fontWeight={500}
            defaultText="Send code"
            textId="app.rentalPage.stepOne.buttonSendCode"
          />
        </MainButton>
      </Stack>
    </>
  );
};
