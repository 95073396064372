import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { DEFAULT_LANGUAGE_CODE } from '~common';

type LanguageContextType = {
  locale: string;
  selectLanguage: (newLocale: string) => void;
  lt: (
    textId: string,
    defaultText: string,
    textValues?: Record<string, unknown>,
  ) => string;
};

const LanguageContext = createContext<LanguageContextType>({
  locale: '',
  selectLanguage: () => null,
  lt: () => '',
});

type Props = {
  children: ReactNode;
};

export const LanguageProvider: React.FC<Props> = ({ children }) => {
  const [locale, setLocale] = useState(DEFAULT_LANGUAGE_CODE);
  const { t, i18n } = useTranslation();

  // localized text function
  const localizedText = (
    textId: string,
    defaultText: string,
    textValues?: Record<string, unknown>,
  ) => {
    if (i18n.isInitialized && i18n.exists(textId)) {
      return t(textId, textValues);
    }

    return defaultText;
  };

  useEffect(() => {
    if (i18n.resolvedLanguage) setLocale(i18n.resolvedLanguage);
  }, [i18n.resolvedLanguage]);

  const selectLanguage = (newLocale: string) => {
    setLocale(newLocale);
    i18n.changeLanguage(newLocale);
  };

  return (
    <LanguageContext.Provider
      value={{ locale, selectLanguage, lt: localizedText }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguageContext = () => useContext(LanguageContext);
