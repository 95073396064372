import { flatten, get } from 'lodash';

import { postRequest } from '~services';
import { FeatureFlag, FeatureFlagResponse } from '~types';

export const featureFlagsManager = () => {
  const FEATURE_FLAGS_PER_PAGE = 200;
  let featureFlags: FeatureFlag[] = [];
  const getFeatureFlags = () => featureFlags;

  const fetchMoreFeatureFlags = async (data: FeatureFlag[], count: number) => {
    const maxPage = Math.ceil((count - data.length) / FEATURE_FLAGS_PER_PAGE);
    const featureFlagsPromise = Array(maxPage)
      .fill(null)
      .map((_, page) => fetchFeatureFlags(page + 1));
    const upcomingFeatureFlags = await Promise.all(featureFlagsPromise);
    featureFlags = featureFlags.concat(
      flatten(upcomingFeatureFlags.map((item) => get(item, 'data', []))),
    );
  };

  const handleFetchFeatureFlags = async () => {
    try {
      const response = await fetchFeatureFlags();
      const { data, count } = response;
      featureFlags = data;
      if (FEATURE_FLAGS_PER_PAGE < count) {
        fetchMoreFeatureFlags(data, count);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Something went wrong when getting feature flags');
    }
  };

  const fetchFeatureFlags: (
    page?: number,
  ) => Promise<FeatureFlagResponse> = async (page = 0) => {
    return postRequest({
      path: 'api/feature-flag/list',
      data: {
        page,
        size: FEATURE_FLAGS_PER_PAGE,
      },
    }).then((res) => res.data as FeatureFlagResponse);
  };

  return {
    getFeatureFlags,
    handleFetchFeatureFlags,
  };
};

export const featureFlagsService = featureFlagsManager();
