import React, { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { createContext, useContext } from 'use-context-selector';

import { API_ENDPOINT_KEYS, QUERY_KEY, SERVICE_AUTH_ENDPOINTS } from '~common';
import { getAuthRequest, inMemoryJWTService } from '~services';
import { LoggedInUserInfo } from '~types';

type LoggedInUserInfoContextType = {
  loggedInUserInfo: LoggedInUserInfo | null;
};

const LoggedInUserInfoContext = createContext<LoggedInUserInfoContextType>({
  loggedInUserInfo: null,
});

const useLoggedInUserInfo = () => {
  const getLoggedInUserInfo = async () =>
    getAuthRequest({
      path: SERVICE_AUTH_ENDPOINTS[API_ENDPOINT_KEYS.GET_PROFILE],
    });

  return useQuery<LoggedInUserInfo>(
    [QUERY_KEY.GET_LOGGED_IN_USER_INFO],
    getLoggedInUserInfo,
  );
};

const useUpdateServices = (loggedInUserInfo: LoggedInUserInfo | null) => {
  React.useEffect(() => {
    if (loggedInUserInfo) {
      inMemoryJWTService.setUserInfo(loggedInUserInfo);
    }
  }, [loggedInUserInfo]);
};

type Props = {
  children: React.ReactNode;
};

export const LoggedInUserInfoProvider: React.FC<Props> = ({ children }) => {
  const { data: loggedInUserInfo } = useLoggedInUserInfo();

  useUpdateServices(loggedInUserInfo ?? null);

  const contextValue = useMemo(
    () => ({ loggedInUserInfo: loggedInUserInfo ?? null }),
    [loggedInUserInfo],
  );

  return (
    <LoggedInUserInfoContext.Provider value={contextValue}>
      {children}
    </LoggedInUserInfoContext.Provider>
  );
};

export const useLoggedInUserInfoContext = () =>
  useContext(LoggedInUserInfoContext);

export const useLoggedInUserInfoQuery = () => {
  const { isLoading, error, data } = useLoggedInUserInfo();
  return { isLoading, error, loggedInUserInfo: data ?? null };
};
