import React, { Fragment } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Typography, TypographyProps } from '@mui/material';

import { LocalizationProps } from './SimpleLocalizationTypography';

export type ComplexLocalizationProps = LocalizationProps & {
  components?: { readonly [tagName: string]: React.ReactElement };
};

/*
 * Fallback component to mimic the behavior of Trans component when i18n instance is not created
 */
const FallbackTrans: React.FC<{
  components: ComplexLocalizationProps['components'];
  defaultText: string;
  textValues?: ComplexLocalizationProps['textValues'];
}> = ({ components, defaultText, textValues }) => {
  const renderNodes = (text: string) => {
    if (!components) return text;

    /*
     * Given a text "This <tag1>is</tag1> a regex <tag2>pattern</tag2>!"
     * The result will be: parts = ["This ", "<tag1>is</tag1>", " a regex ", "<tag2>pattern</tag2>", "!"]
     */
    const parts = text.split(/(<\w+>.*?<\/\w+>)/g);
    return parts.map((part, index) => {
      /*
       * Given a text "<tag2>is</tag2>"
       * The result will be: match = ["<tag2>is</tag2>", "1", "pattern"]
       */
      const match = part.match(/<(\w+)>(.*?)<\/\w+>/);
      if (match) {
        const componentKey = match[1];
        const Component = components[componentKey];
        return React.cloneElement(Component, { key: index }, match[2]);
      }
      return <Fragment key={index}>{part}</Fragment>;
    });
  };

  let renderedText = defaultText;
  if (textValues) {
    Object.keys(textValues).forEach((key) => {
      const value = textValues[key];
      renderedText = renderedText.replace(new RegExp(`{{${key}}}`, 'g'), value);
    });
  }

  return <>{renderNodes(renderedText)}</>;
};

/*
 * The purpose of this function is to display COMPLEX localization text with integrated React/HTML nodes
 * with cohesive formatting such as strong, em, and link components within the text.
 */
export const ComplexLocalizationTypography: React.FC<
  TypographyProps & ComplexLocalizationProps
> = ({
  disableParentTypography,
  textId,
  textValues,
  components,
  defaultText,
  children,
  ...typographyProps
}) => {
  // ready is to determine whether the translations have fully loaded or not
  const { t, ready } = useTranslation();

  const renderText = () => (
    <>
      {ready ? (
        <Trans
          t={t}
          i18nKey={textId}
          components={components}
          values={textValues}
          defaults={defaultText}
        />
      ) : (
        <FallbackTrans
          components={components}
          textValues={textValues}
          defaultText={defaultText}
        />
      )}
      {children}
    </>
  );

  return disableParentTypography ? (
    renderText()
  ) : (
    <Typography {...typographyProps}>{renderText()}</Typography>
  );
};
