import { useQuery } from '@tanstack/react-query';

import { QUERY_KEY } from '~common';
import { getRequest } from '~services';
import { inMemoryJWTService } from '~services/jwt.service';

export const useQueryGetPreAuthAmount = (serialNumber: string) => {
  return useQuery(
    [QUERY_KEY.GET_STATION_PRE_AUTH],
    () => {
      return getRequest({
        path: 'api/station/pre-auth-amount',
        config: {
          params: {
            serialNumber: serialNumber,
          },
        },
      }).then((res) => res);
    },
    {
      enabled: !!inMemoryJWTService.getCurrentToken() && !!serialNumber,
    },
  );
};
