import React, { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useLocalStorage, useMount, useUpdateEffect } from 'react-use';
import { createContext, useContext } from 'use-context-selector';

import { useLanguageContext } from './LanguageProvider';
import { LoggedInUserInfoProvider } from './LoggedInUserInfoProvider';

import {
  API_ENDPOINT_KEYS,
  DEFAULT_COUNTRY_DATA,
  PAYMENT_METHOD,
  SERVICE_AUTH_ENDPOINTS,
  STEP,
} from '~common';
import { AUTH_API_CLIENT_ID } from '~configs';
import {
  useCaptcha,
  useNavigateInApp,
  useQueryGetPaymentInfo,
  useQueryGetRentalInfo,
} from '~hooks';
import { useQueryGetPreAuthAmount } from '~hooks/useQueryGetPreAuthAmount';
import { useQueryGetRentalState } from '~hooks/useQueryGetRentalState';
import { useEventTrackingContext } from '~providers/EventTrackingProvider';
import { postAuthRequest } from '~services';
import { inMemoryJWTService } from '~services/jwt.service';
import { PaymentInfo } from '~types';
import { centToUSD, isValidPhoneNumber } from '~utils';

type MainContextType = {
  step: STEP | null;
  preAuthAmount: string;
  changeStep: (step: STEP) => void;
  handleSubmitGetOTP: () => void;
  getOTP: (captchaToken?: string) => Promise<void>;
  login: (code: string) => Promise<void>;
  isLoadingLogin: boolean;
  isLoadingOTP: boolean;
  initialComplete: boolean;
  phoneNumber: string;
  authToken: string | undefined;
  cachedPhoneNumber: string | undefined;
  setPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
  setIsEnabledEjectBtn: React.Dispatch<React.SetStateAction<boolean>>;
  setIsEnabledGetOTP: React.Dispatch<React.SetStateAction<boolean>>;
  paymentMethod: PAYMENT_METHOD;
  onChangePaymentMethod: (method: PAYMENT_METHOD) => () => void;
  isEnabledEjectBtn: boolean;
  handleEditPaymentMethod: () => void;
  paymentInfo: PaymentInfo | null;
  stationSerialNumber: string | null;
  refreshCaptcha: number;
  setRefreshCaptcha: React.Dispatch<React.SetStateAction<number>>;
  captchaVerified: boolean;
  onCaptchaVerified: (token: string) => void;
  onCaptchaExpired: () => void;
  captchaToken: string | undefined;
  agreePolicy: boolean;
  onAgreePolicy: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rentalQuery?: ReturnType<typeof useQueryGetRentalInfo>;
  saveRentalId: (id: string) => void;
  rentalId: string;
};

const MainContext = createContext<MainContextType>({
  step: null,
  preAuthAmount: '0',
  changeStep: () => null,
  setIsEnabledEjectBtn: () => null,
  setIsEnabledGetOTP: () => null,
  handleSubmitGetOTP: () => null,
  getOTP: () => Promise.resolve(),
  login: () => Promise.resolve(),
  phoneNumber: `+${DEFAULT_COUNTRY_DATA.dialCode}`,
  cachedPhoneNumber: undefined,
  handleEditPaymentMethod: () => null,
  setPhoneNumber: () => null,
  isLoadingOTP: false,
  isLoadingLogin: false,
  initialComplete: false,
  paymentMethod: PAYMENT_METHOD.CREDIT_CARD,
  onChangePaymentMethod: () => () => null,
  isEnabledEjectBtn: false,
  authToken: undefined,
  paymentInfo: null,
  stationSerialNumber: '',
  refreshCaptcha: 0,
  setRefreshCaptcha: () => null,
  captchaVerified: false,
  onCaptchaVerified: () => null,
  onCaptchaExpired: () => null,
  captchaToken: undefined,
  agreePolicy: false,
  onAgreePolicy: () => null,
  saveRentalId: () => null,
  rentalId: '',
});

type Props = {
  children: React.ReactNode;
};

export const MainProvider: React.FC<Props> = ({ children }) => {
  const [step, setStep] = React.useState<STEP | null>(null);
  const [initialComplete, setInitialComplete] = React.useState(false);
  const [isLoadingOTP, setIsLoadingOTP] = useState(false);
  const [isLoadingLogin, setIsLoadingLogin] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(
    `+${DEFAULT_COUNTRY_DATA.dialCode}`,
  );
  const [authToken, setAuthToken, remove] = useLocalStorage<string>(
    'cf-webapp-auth-token',
  );
  const [cachedPhoneNumber, setCachedPhoneNumber, removeCachedPhoneNumber] =
    useLocalStorage<string>('cf-webapp-phone-number');

  const [isNavigateToWelcomeBackPage, setIsNavigateToWelcomeBackPage] =
    useState(true);

  const [searchParams] = useSearchParams();

  const [paymentMethod, setPaymentMethod] = useState<PAYMENT_METHOD>(
    PAYMENT_METHOD.CREDIT_CARD,
  );

  const [refreshCaptcha, setRefreshCaptcha] = useState(0);

  const [isEnabledGetOTP, setIsEnabledGetOTP] = useState(false);

  const [isEnabledEjectBtn, setIsEnabledEjectBtn] = useState(false);

  const [agreePolicy, setAgreePolicy] = useState(false);
  const [rentalId, setRentalId] = useState('');

  const { lt } = useLanguageContext();
  const navigate = useNavigateInApp();
  const location = useLocation();
  const { identifyUser } = useEventTrackingContext();

  const clearAllCached = () => {
    remove();
    removeCachedPhoneNumber();
  };

  const paymentInfoQuery = useQueryGetPaymentInfo();

  const postPageLoadedProcess = async () => {
    // check if user is logged in
    if (authToken) {
      try {
        await inMemoryJWTService.getNewAccessToken().then((data) => {
          setAuthToken(data.accessToken);
        });
      } catch (error) {
        // clear local storage and logout, then redirect to input phone page
        clearAllCached();
        setInitialComplete(true);
        setStep(STEP.INPUT_PHONE_NUMBER);

        return;
      }

      // user logged in, check if payment info is available
      try {
        // handle edit payment in welcome back page
        if (!isNavigateToWelcomeBackPage) return;
        // do not redirect to welcome back page if user is in welcome back / success page
        if (
          location.pathname === '/welcome-back' ||
          location.pathname === '/success'
        ) {
          setInitialComplete(true);
          return;
        }
        // payment info available, redirect to welcome back page
        navigate('/welcome-back');
        setInitialComplete(true);
      } catch (error) {
        if (location.pathname === '/welcome-back') {
          // We are in welcome back page but the payment info doesn't exist.
          // Navigate to input payment info page
          setInitialComplete(true);
          setStep(STEP.INPUT_PAYMENT_INFO);
          navigate('/fuzebox');
          return;
        }
        // payment info not available, redirect to input payment info page
        setInitialComplete(true);
        setStep(STEP.INPUT_PAYMENT_INFO);
      }
    } else {
      // user not logged in, redirect to input phone page
      setInitialComplete(true);
      clearAllCached();
      setStep(STEP.INPUT_PHONE_NUMBER);
    }
  };

  useMount(async () => {
    await postPageLoadedProcess();
  });

  const { captchaVerified, onCaptchaVerified, onCaptchaExpired, captchaToken } =
    useCaptcha();

  const changeStep = (step: STEP) => setStep(step);

  const handleSubmitGetOTP = () => {
    // Refresh reCaptcha token before getting OTP code
    setRefreshCaptcha((r) => r + 1);
    setIsEnabledGetOTP(true);
  };

  const getOTP = async () => {
    if (!isValidPhoneNumber(phoneNumber)) return;
    setCachedPhoneNumber(phoneNumber);
    try {
      setIsLoadingOTP(true);
      const body = {
        phone_number: phoneNumber,
        platform: 'WEB',
        recaptcha_token: captchaToken,
        language: 'en',
      };
      const request = postAuthRequest({
        path: SERVICE_AUTH_ENDPOINTS[API_ENDPOINT_KEYS.SEND_CODE],
        data: {
          ...body,
          client_id: AUTH_API_CLIENT_ID,
        },
      });
      await request.then(() => {
        toast.success(lt('text.loginCodeSent', 'Login code has been sent'));
      });

      setStep(STEP.INPUT_OTP);
    } catch (error) {
      toast.error(lt('text.getOtpFailed', 'Get OTP code failed'));
    } finally {
      setIsLoadingOTP(false);
    }
  };

  const login = async (code: string) => {
    try {
      setIsLoadingLogin(true);
      const body = {
        phone_number: phoneNumber,
        code: code,
      };
      const request = postAuthRequest({
        path: SERVICE_AUTH_ENDPOINTS[API_ENDPOINT_KEYS.VERIFY_CODE],
        data: {
          ...body,
          client_id: AUTH_API_CLIENT_ID,
        },
      });
      await request.then((res) => {
        const { user, accessToken, expiresIn, refreshToken } = res.data;

        inMemoryJWTService.setToken(accessToken, expiresIn);
        inMemoryJWTService.setRefreshToken(refreshToken);
        identifyUser(user.id);

        setAuthToken(accessToken);
      });
    } catch (error) {
      toast.error(lt('text.loginFailed', 'Login failed'));
    } finally {
      setIsLoadingLogin(false);
    }
  };

  useUpdateEffect(() => {
    if (paymentInfoQuery?.data?.paymentMethodId) {
      navigate('/welcome-back');
    } else if (authToken) {
      setStep(STEP.INPUT_PAYMENT_INFO);
    } else {
      navigate('/fuzebox');
    }
  }, [authToken, paymentInfoQuery?.data]);

  useEffect(() => {
    if (isEnabledGetOTP) {
      getOTP();
    }
    setIsEnabledGetOTP(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [captchaToken]);

  const onChangePaymentMethod = (paymentMethod: PAYMENT_METHOD) => () => {
    setPaymentMethod(paymentMethod);
  };

  const handleEditPaymentMethod = () => {
    setStep(STEP.INPUT_PAYMENT_INFO);
    setIsNavigateToWelcomeBackPage(false);
    navigate('/fuzebox');
  };

  const onAgreePolicy = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgreePolicy(event.target.checked);
  };

  const rentalQuery = useQueryGetRentalState(rentalId);
  const stationSerialNumber = searchParams.get('box');
  const preAuthQuery = useQueryGetPreAuthAmount(stationSerialNumber ?? '');

  const saveRentalId = (id: string) => {
    setRentalId(id);
  };

  return (
    <MainContext.Provider
      value={{
        paymentInfo: paymentInfoQuery?.data,
        preAuthAmount: centToUSD(preAuthQuery?.data?.amount),
        cachedPhoneNumber,
        authToken,
        handleEditPaymentMethod,
        isEnabledEjectBtn,
        setIsEnabledEjectBtn,
        setIsEnabledGetOTP,
        paymentMethod,
        initialComplete,
        isLoadingLogin,
        isLoadingOTP,
        step,
        changeStep,
        handleSubmitGetOTP,
        getOTP,
        phoneNumber,
        setPhoneNumber,
        login,
        onChangePaymentMethod,
        stationSerialNumber,
        refreshCaptcha,
        setRefreshCaptcha,
        captchaVerified,
        onCaptchaVerified,
        onCaptchaExpired,
        captchaToken,
        agreePolicy,
        onAgreePolicy,
        rentalQuery,
        saveRentalId,
        rentalId,
      }}
    >
      {authToken && inMemoryJWTService.getCurrentToken() ? (
        <LoggedInUserInfoProvider>{children}</LoggedInUserInfoProvider>
      ) : (
        children
      )}
    </MainContext.Provider>
  );
};

export const useMainContext = () => useContext(MainContext);
