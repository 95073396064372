import React from 'react';
import { Close } from '@mui/icons-material';
import { IconButton, InputAdornment } from '@mui/material';
import MuiPhoneNumber from 'material-ui-phone-number';

import { DEFAULT_COUNTRY_DATA } from '~common';
import { getInternationalDialCodes } from '~configs';
import { useLanguageContext } from '~providers';
import { isValidPhoneNumber } from '~utils';

type Props = {
  children?: React.ReactNode;
  phoneNumber: string;
  setPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
  isValid: boolean | null;
  setIsValid: React.Dispatch<React.SetStateAction<boolean | null>>;
};

export const PhoneInput: React.FC<Props> = ({
  phoneNumber,
  setPhoneNumber,
  isValid,
  setIsValid,
}) => {
  const inputRef = React.useRef<never>(null);
  const rightAfterClearRef = React.useRef(false);
  const rightAfterChangeRef = React.useRef(false);

  const { lt } = useLanguageContext();

  const onChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string,
  ) => {
    // reset tracking check valid phone number variable
    rightAfterChangeRef.current = true;
    rightAfterClearRef.current = false;
    if (!isValid) setIsValid(null);
    if (typeof event === 'string') {
      let newNumber = `${event}`;
      newNumber = newNumber.replace(/[^\d+]/g, '');

      // user is selecting country code from dropdown
      if (getInternationalDialCodes().includes(newNumber.replace(/\D/g, ''))) {
        setPhoneNumber(newNumber);
        return;
      }
      // prevent autofill when init
      // allow change country code after input number
      if (phoneNumber.length > 2) {
        setPhoneNumber(newNumber);
        return;
      }
      if (
        // phoneNumber: +1
        // autofill: 4051234567
        // newNumber: +14051234567
        !newNumber.startsWith(phoneNumber) &&
        // detect when user delete last number
        // phoneNumber: +14051234567
        // newNumber: +1405123456
        !phoneNumber.startsWith(newNumber)
      ) {
        // user use autofill feature on safari
        newNumber = phoneNumber + newNumber.replace(/\D/g, '');
      }

      // reset tracking check valid phone number variable
      rightAfterChangeRef.current = true;
      rightAfterClearRef.current = false;

      if (!isValid) {
        setIsValid(null);
      }
      setPhoneNumber(newNumber);
    }
  };

  const onBlur = () => {
    // don't call check valid phone number after click clear button
    // or focus and blur without input number
    if (!rightAfterClearRef.current && rightAfterChangeRef.current) {
      setIsValid(isValidPhoneNumber(phoneNumber));
      return;
    }
    rightAfterClearRef.current = false;
    rightAfterChangeRef.current = false;
  };

  const handleClear = () => {
    // don't call check valid phone number after click clear button
    rightAfterClearRef.current = true;
    // focus input after clear
    (
      inputRef.current as unknown as Record<string, Record<string, () => void>>
    )?.inputRef?.focus();
    setPhoneNumber('+1');
    // reset tracking check valid phone number variable
    setIsValid(null);
  };

  return (
    <MuiPhoneNumber
      ref={inputRef}
      value={phoneNumber}
      defaultCountry={DEFAULT_COUNTRY_DATA.countryCode}
      preferredCountries={['us', 'vn']}
      variant="outlined"
      size="small"
      countryCodeEditable={false}
      placeholder={lt('placeholder.phoneInput', 'Phone Number')}
      required
      error={isValid === false}
      onChange={onChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {phoneNumber && (
              <IconButton edge="end" onClick={handleClear}>
                <Close />
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
      sx={{
        '& .MuiInputBase-root': { height: 54 },
        '& .MuiOutlinedInput-notchedOutline': { borderWidth: '1px !important' },
        '& svg': { height: '1.3rem' },
      }}
      onBlur={onBlur}
      helperText={
        isValid === false ? (
          lt('text.invalidPhoneNumber', 'Invalid phone number')
        ) : (
          <span>&#8203;</span>
        )
      }
    />
  );
};
