import { useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { MuiOtpInput } from 'mui-one-time-password-input';

import { LocalizationTypography } from './localization';

import { STEP, TRACKING_BUTTON, TRACKING_PAGES } from '~common';
import { MainButton } from '~components/atoms';
import { useEventTrackingContext, useMainContext } from '~providers';
import { formatNumber } from '~utils/phone.util';

export const StepTwo = () => {
  const { recordPageVisited, recordBtnClicked } = useEventTrackingContext();
  const {
    phoneNumber,
    login,
    isLoadingLogin,
    isLoadingOTP,
    changeStep,
    handleSubmitGetOTP,
  } = useMainContext();
  const [countdownTime, setCountdownTime] = useState(0);
  const [intervalId, setIntervalId] = useState<NodeJS.Timer>();
  const [otpCode, setOTPCode] = useState<string>('');

  useEffect(() => {
    recordPageVisited(TRACKING_PAGES.ENTER_VERIFICATION_CODE);
  }, [recordPageVisited]);

  const onChangeOTPCode = (value: string) => {
    setOTPCode(value);
  };

  const onLoginClick = () => {
    login(otpCode);
    recordBtnClicked(TRACKING_BUTTON.CONFIRM_VERIFICATION_CODE);
  };

  function handleResendOTPClick() {
    handleSubmitGetOTP();
    setCountdownTime(30);
    // Disable the resend OTP button
    setIntervalId(
      setInterval(function () {
        setCountdownTime((prevCountdownTime) => prevCountdownTime - 1);
      }, 1000),
    );
    recordBtnClicked(TRACKING_BUTTON.RESEND_VERIFICATION_CODE);
  }

  useEffect(() => {
    if (countdownTime === 0 && intervalId) {
      // Enable the resend OTP button
      clearInterval(intervalId);
    }
  }, [countdownTime, intervalId]);

  return (
    <>
      <Stack display="grid" sx={{ gridRowGap: 16 }}>
        <LocalizationTypography
          variant="caption"
          fontWeight={500}
          color="#6A737D"
          textId="app.rentalPage.stepTwo.step"
          defaultText="STEP 2 OF 3"
        />
        <LocalizationTypography
          variant="h5"
          fontWeight={600}
          textId="app.rentalPage.stepTwo.enterCode"
          defaultText="Enter verification code"
        />
        <Stack direction="row" gap={0.4} flexWrap={'wrap'}>
          <LocalizationTypography
            variant="body1"
            fontWeight={400}
            color="#6A737D"
            textId="app.rentalPage.stepTwo.codeSentTo"
            defaultText="We sent a verification code to"
          />
          <Typography
            variant="body1"
            fontWeight={400}
            color="primary.main"
            onClick={() => changeStep(STEP.INPUT_PHONE_NUMBER)}
          >
            {formatNumber(phoneNumber?.replace(/\D/g, ''))}
          </Typography>
        </Stack>
      </Stack>
      <MuiOtpInput
        sx={{
          gap: 0,
          '& > div:not(:last-of-type)': {
            marginRight: 2,
          },
          '& .MuiInputBase-input': {
            paddingX: 2,
            paddingY: 3,
            fontSize: '1.5rem',
          },
        }}
        TextFieldsProps={{
          type: 'number',
          inputProps: {
            inputMode: 'numeric',
            pattern: '[0-9]*',
          },
        }}
        inputMode="numeric"
        autoFocus
        value={otpCode}
        onChange={onChangeOTPCode}
      />
      <Stack display="grid" sx={{ gridRowGap: 24 }}>
        <MainButton
          disabled={!otpCode || otpCode?.length !== 4 || isLoadingLogin}
          variant="contained"
          onClick={onLoginClick}
          isLoading={isLoadingLogin}
        >
          <LocalizationTypography
            variant="body1"
            fontWeight={500}
            textId="app.rentalPage.stepTwo.button.continue"
            defaultText="Continue"
          />
        </MainButton>
        <MainButton
          isLoading={isLoadingOTP}
          disabled={countdownTime > 0 || isLoadingOTP}
          variant="text"
          onClick={handleResendOTPClick}
        >
          {countdownTime > 0 ? (
            <LocalizationTypography
              variant="body1"
              fontWeight={500}
              textId="app.rentalPage.stepTwo.button.resendInProgress"
              defaultText={`Resend Code in ${countdownTime}s`}
              textValues={{ value: countdownTime }}
            />
          ) : (
            <LocalizationTypography
              variant="body1"
              fontWeight={500}
              textId="app.rentalPage.stepTwo.button.resend"
              defaultText="Resend Code"
            />
          )}
        </MainButton>
      </Stack>
    </>
  );
};
