import axios from 'axios';
import applyCaseMiddleware from 'axios-case-converter';

import i18n from '../i18n';

import { DEFAULT_LANGUAGE_CODE } from '~common';
import { API_ENDPOINT } from '~configs';
import { getAuthHeader, GetParams, PostParams } from '~services';

export const axiosClient = applyCaseMiddleware(
  axios.create({
    baseURL: API_ENDPOINT,
    // https://github.com/axios/axios/issues/5058#issuecomment-1272107602
    // Example: Params { a: ['b', 'c']}
    // From (by default - false) 'a[]=b&a[]=c'
    // To (by null) 'a=b&a=c'
    paramsSerializer: {
      indexes: null, // by default: false
    },
  }),
  {
    ignoreHeaders: true,
  },
);

export const getRequest = async <T>({
  path,
  config,
  customHeader,
  params,
  ignoreAuthHeader,
}: GetParams<T>) => {
  const authHeader = await getAuthHeader(ignoreAuthHeader);
  const response = await axiosClient.get(`/${path}`, {
    headers: {
      Accept: 'application/json',
      'Accept-Language': i18n.resolvedLanguage || DEFAULT_LANGUAGE_CODE,
      ...authHeader,
      ...customHeader,
    },
    params,
    ...config,
  });
  return response.data;
};

export const postRequest = async <T>({
  path,
  config,
  customHeader,
  data = {} as T,
  ignoreAuthHeader,
}: PostParams<T>) => {
  const authHeader = await getAuthHeader(ignoreAuthHeader);
  return axiosClient.post(`/${path}`, data, {
    headers: {
      Accept: 'application/json',
      'Accept-Language': i18n.resolvedLanguage || DEFAULT_LANGUAGE_CODE,
      ...authHeader,
      ...customHeader,
    },
    ...config,
  });
};

export const putRequest = async <T>({
  path,
  config,
  customHeader,
  data,
  ignoreAuthHeader,
}: PostParams<T>) => {
  const authHeader = await getAuthHeader(ignoreAuthHeader);
  return axiosClient.put(`/${path}`, data, {
    headers: {
      Accept: 'application/json',
      'Accept-Language': i18n.resolvedLanguage || DEFAULT_LANGUAGE_CODE,
      ...authHeader,
      ...customHeader,
    },
    ...config,
  });
};

export const patchRequest = async <T>({
  path,
  config,
  customHeader,
  data,
  ignoreAuthHeader,
}: PostParams<T>) => {
  const authHeader = await getAuthHeader(ignoreAuthHeader);
  return axiosClient.patch(`/${path}`, data, {
    headers: {
      Accept: 'application/json',
      'Accept-Language': i18n.resolvedLanguage || DEFAULT_LANGUAGE_CODE,
      ...authHeader,
      ...customHeader,
    },
    ...config,
  });
};

export const delRequest = async <T>({
  path,
  config,
  customHeader,
  ignoreAuthHeader,
}: GetParams<T>) => {
  const authHeader = await getAuthHeader(ignoreAuthHeader);
  return axiosClient.delete(`/${path}`, {
    headers: {
      Accept: 'application/json',
      'Accept-Language': i18n.resolvedLanguage || DEFAULT_LANGUAGE_CODE,
      ...authHeader,
      ...customHeader,
    },
    ...config,
  });
};
