import { useEffect } from 'react';

import { TRACKING_PAGES } from '~common';
import { WelcomeBack } from '~components/organisms';
import { useEventTrackingContext } from '~providers';

export const WelcomeBackPage = () => {
  const { recordPageVisited } = useEventTrackingContext();

  useEffect(() => {
    recordPageVisited(TRACKING_PAGES.WELCOME_BACK);
  }, [recordPageVisited]);

  return <WelcomeBack />;
};
