import { AxiosRequestConfig, RawAxiosRequestHeaders } from 'axios';

import { inMemoryJWTService } from './jwt.service';

export type GetParams<T> = {
  path: string;
  config?: AxiosRequestConfig;
  customHeader?: RawAxiosRequestHeaders;
  applyMiddleWare?: boolean;
  params?: T;
  ignoreAuthHeader?: boolean;
};

export type PostParams<T> = GetParams<T> & {
  data?: T;
};

export const getAuthHeader = async (
  ignoreToken?: boolean,
): Promise<RawAxiosRequestHeaders> => {
  if (ignoreToken == true) {
    return {};
  }
  const token = await inMemoryJWTService.getValidToken();
  return {
    Authorization: `Bearer ${token}`,
  };
};
