import { usdFormatter } from '~configs';
import { featureFlagsService } from '~services';

export * from './phone.util';

export function iOS() {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
}

export function isAndroid() {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf('android') > -1;
}

export const handleOpenApp = () => {
  window?.open(
    iOS()
      ? process.env.REACT_APP_IOS_DEEP_LINK
      : isAndroid()
      ? process.env.REACT_APP_ANDROID_DEEP_LINK
      : process.env.REACT_APP_CF_MAIN_PAGE_LINK,
  );
};

/**
 * Converts an amount from cents to a formatted USD string.
 * If the amount is null or undefined, returns a placeholder string.
 *
 * @param {number | undefined | null} amount - The amount in cents to be converted.
 *        This can be a number, or null/undefined if the amount is not known.
 * @returns {string} The formatted amount in USD, e.g., "$20", or "$--" if the input is nullish.
 */
export const centToUSD = (amount: number | undefined | null): string => {
  if (amount == null) {
    return '$--';
  }
  return usdFormatter.format(amount / 100);
};

export function checkEnabledFeatureFlag(key: string) {
  const featureFlags = featureFlagsService.getFeatureFlags();
  const featureFlag = featureFlags.find(
    (featureFlag) => featureFlag.key === key,
  );
  return !!featureFlag?.enabled;
}

export function checkEnabledFeatureFlags(keys: string[]) {
  return keys.every((flag) => checkEnabledFeatureFlag(flag));
}
