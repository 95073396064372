import React, { useEffect } from 'react';
import {
  Box,
  CircularProgress,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import {
  StripeCardCvcElementChangeEvent,
  StripeCardCvcElementOptions,
  StripeCardExpiryElementChangeEvent,
  StripeCardExpiryElementOptions,
  StripeCardNumberElementChangeEvent,
  StripeCardNumberElementOptions,
} from '@stripe/stripe-js';

import { MainButton } from '~components/atoms';
import { useRedeemReward } from '~hooks/index';
import { useLanguageContext, useMainContext } from '~providers';

type Props = {
  error: string | null;
  processing: boolean;
};

export const CreditCardPaymentForm: React.FC<Props> = ({
  error,
  processing,
}) => {
  const { lt } = useLanguageContext();

  const { setIsEnabledEjectBtn, isEnabledEjectBtn } = useMainContext();
  const {
    rewardRedeemError,
    promoteCode,
    onChangePromoteCode,
    redeemReward,
    isRedeemRewardLoading,
    rewardRedeemSuccessMessage,
  } = useRedeemReward();

  const stripe = useStripe();
  const elements = useElements();

  const [isCardNumberValid, setIsCardNumberValid] =
    React.useState<boolean>(false);
  const [isCardCvcValid, setIsCardCvcValid] = React.useState<boolean>(false);
  const [isCardExpiryValid, setIsCardExpiryValid] =
    React.useState<boolean>(false);
  const [cardNumberError, setCardNumberError] = React.useState<string | null>(
    null,
  );
  const [cardCvcError, setCardCvcError] = React.useState<string | null>(null);
  const [cardExpiryError, setCardExpiryError] = React.useState<string | null>(
    null,
  );

  const onCreditCardNumberChange = (
    event: StripeCardNumberElementChangeEvent,
  ) => {
    if (event.error) {
      setCardNumberError(
        lt(`stripeError.${event.error.code}`, event.error.message),
      );

      return;
    }

    if (cardNumberError) {
      setCardNumberError(null);
    }

    setIsCardNumberValid(event.complete);
  };

  const onCreditCardCvcChange = (event: StripeCardCvcElementChangeEvent) => {
    if (event.error) {
      setCardCvcError(
        lt(`stripeError.${event.error.code}`, event.error.message),
      );

      return;
    }

    if (cardCvcError) {
      setCardCvcError(null);
    }
    setIsCardCvcValid(event.complete);
  };

  const onCreditCardExpiryChange = (
    event: StripeCardExpiryElementChangeEvent,
  ) => {
    if (event.error) {
      setCardExpiryError(
        lt(`stripeError.${event.error.code}`, event.error.message),
      );

      return;
    }

    if (cardExpiryError) {
      setCardExpiryError(null);
    }

    setIsCardExpiryValid(event.complete);
  };

  useEffect(() => {
    if (
      (isRedeemRewardLoading || processing || !stripe || !elements) &&
      isEnabledEjectBtn
    ) {
      setIsEnabledEjectBtn(false);
    }
  }, [
    processing,
    stripe,
    elements,
    setIsEnabledEjectBtn,
    isEnabledEjectBtn,
    isRedeemRewardLoading,
  ]);

  useEffect(() => {
    setIsEnabledEjectBtn(
      isCardNumberValid &&
        isCardCvcValid &&
        isCardExpiryValid &&
        !isRedeemRewardLoading,
    );
  }, [
    isRedeemRewardLoading,
    isCardNumberValid,
    isCardCvcValid,
    isCardExpiryValid,
    setIsEnabledEjectBtn,
  ]);

  const commonCardElementStyle = {
    base: {
      fontSize: '16px',
      fontFamily: 'Helvetica Neue',
      fontWeight: 400,
      color: '#2F363D',
      '::placeholder': {
        color: '#6A737D',
      },
    },
  };

  const cardNumberElementOptions: StripeCardNumberElementOptions = {
    style: commonCardElementStyle,
    placeholder: lt('placeholder.cardNumberElement', 'Card Number'),
    showIcon: true,
    iconStyle: 'solid',
  };

  const cardCvcElementOptions: StripeCardCvcElementOptions = {
    style: commonCardElementStyle,
  };

  const cardExpiryElementOptions: StripeCardExpiryElementOptions = {
    style: commonCardElementStyle,
  };

  return (
    <Stack display="grid" sx={{ gridRowGap: 24 }}>
      <Stack display="grid" sx={{ gridRowGap: 8 }}>
        <Box
          sx={{
            border: '1px solid',
            p: 2,
            borderRadius: 1,
            borderColor: `${cardNumberError ? `error.main` : '#DBDBDB'}`,
          }}
        >
          <CardNumberElement
            options={cardNumberElementOptions}
            onChange={onCreditCardNumberChange}
          />
        </Box>
        {cardNumberError && (
          <Typography variant={'body2'} fontWeight={500} color="error">
            {cardNumberError}
          </Typography>
        )}
      </Stack>

      <Stack display="grid" sx={{ gridRowGap: 8 }}>
        <Box display="flex" justifyContent={'space-between'}>
          <Box
            sx={{
              width: '70%',
              border: '1px solid #DBDBDB',
              p: 2,
              borderRadius: 1,
              borderColor: `${cardExpiryError ? `error.main` : '#DBDBDB'}`,
            }}
            display={'inline-block'}
          >
            <CardExpiryElement
              options={cardExpiryElementOptions}
              onChange={onCreditCardExpiryChange}
            />
          </Box>
          <Box
            sx={{
              width: '25%',
              border: '1px solid #DBDBDB',
              p: 2,
              borderRadius: 1,
              borderColor: `${cardCvcError ? `error.main` : '#DBDBDB'}`,
            }}
            display={'inline-block'}
          >
            <CardCvcElement
              onChange={onCreditCardCvcChange}
              options={cardCvcElementOptions}
            />
          </Box>
        </Box>
        {(cardCvcError || cardExpiryError) && (
          <Typography variant={'body2'} fontWeight={500} color="error">
            {cardCvcError ?? cardExpiryError}
          </Typography>
        )}
      </Stack>
      <Stack>
        <TextField
          error={!!rewardRedeemError}
          variant="outlined"
          placeholder={lt('placeholder.promoInput', 'Have a promo code?')}
          onChange={onChangePromoteCode}
          sx={{
            '& .MuiInputBase-root': {
              paddingRight: 'unset',
            },
            '& input::placeholder': {
              color: '#6A737D',
              fontWeight: 400,
              fontSize: '1rem',
              fontFamily: 'Helvetica Neue',
            },
            '& .MuiInputAdornment-root': {
              height: '100%',
              maxHeight: 'unset',
            },
          }}
          InputProps={{
            sx: {
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#DBDBDB',
              },
              '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                borderColor: 'error.main',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderWidth: '1px !important',
                boxShadow: 'unset !important',
                borderColor: '#DBDBDB',
              },
            },
            endAdornment: (
              <InputAdornment position="end">
                <MainButton
                  sx={{
                    border: 'unset',
                    height: '100%',
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    borderTopRightRadius: '8px',
                    borderBottomRightRadius: '8px',
                  }}
                  variant="contained"
                  onClick={redeemReward}
                  disabled={isRedeemRewardLoading || !promoteCode}
                >
                  {isRedeemRewardLoading ? (
                    <CircularProgress size="16px" color="inherit" />
                  ) : (
                    lt('text.apply', 'Apply')
                  )}
                </MainButton>
              </InputAdornment>
            ),
          }}
        />
        {rewardRedeemError && (
          <Stack direction="row" sx={{ marginTop: 1 }} alignItems="center">
            <img src="/images/error.svg" alt="error" width={13} />
            <Typography
              variant="body2"
              fontWeight={500}
              color="error.main"
              marginLeft={1}
            >
              {rewardRedeemError}
            </Typography>
          </Stack>
        )}
        {rewardRedeemSuccessMessage && (
          <Stack direction="row" sx={{ marginTop: 1 }} alignItems="center">
            <img src="/images/check_circle.svg" alt="success" width={13} />
            <Typography
              variant="body2"
              fontWeight={500}
              color="success.main"
              marginLeft={1}
            >
              {rewardRedeemSuccessMessage}
            </Typography>
          </Stack>
        )}
        {error && (
          <Typography variant={'body2'} fontWeight={500} color="error">
            {error}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};
