import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Box, Stack } from '@mui/material';

import { Captcha, NavBar } from '~components/atoms';
import { LocalizationTypography } from '~components/organisms';
import { EventTrackingProvider, MainProvider } from '~providers';

export const MainLayout: React.FC = () => {
  const location = useLocation();
  const disableHeader = location.pathname === '/success';

  return (
    <EventTrackingProvider>
      <MainProvider>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100vw',
            height: 'fit-content',
            maxWidth: '1024px',
            margin: 'auto',
          }}
        >
          {/* Navbar */}
          {!disableHeader && <NavBar />}

          {/* Captcha */}
          <Stack alignItems={'center'}>
            <Captcha />
          </Stack>

          {/* Outlet display */}
          <Stack
            overflow={'auto'}
            sx={{
              justifyContent: 'space-between',
              flex: 1,
              marginTop: disableHeader ? 0 : '90px',
            }}
          >
            <Stack flex={1}>
              <Outlet />
            </Stack>
            <Stack
              bgcolor={'white'}
              color="secondary"
              justifyContent={'center'}
              alignItems="center"
              direction="row"
              p={3.5}
            >
              <img src="/images/chat-bubble.svg" alt="chat-icon" width={'24'} />
              <Box
                component={'a'}
                href="sms:+1 (213) 451-6667"
                sx={{ textDecoration: 'unset' }}
              >
                <LocalizationTypography
                  variant="body2"
                  fontWeight={500}
                  color={'primary'}
                  marginLeft={1}
                  textId="app.mainLayout.customerSupportText"
                  defaultText="Questions? Text customer support"
                />
              </Box>
            </Stack>
          </Stack>
        </Box>
      </MainProvider>
    </EventTrackingProvider>
  );
};
