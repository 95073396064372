import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useElements, useStripe } from '@stripe/react-stripe-js';

import { useMutationRequestBattery } from './useMutationRequestBattery';
import { useMutationSavePaymentInfo } from './useMutationSavePaymentInfo';

import { useLanguageContext, useMainContext } from '~providers';

export const useEjectBattery = () => {
  const [error, setError] = useState<string | null>(null);
  const [searchParams] = useSearchParams();
  const [isFetchingRental, setIsFetchingRental] = useState(false);
  const [isRequesting, setIsRequesting] = useState(false);
  const requestBatteryMutation = useMutationRequestBattery();
  const savePaymentInfoMutation = useMutationSavePaymentInfo();

  const { lt } = useLanguageContext();

  const { paymentMethod, rentalQuery, saveRentalId } = useMainContext();
  const stripe = useStripe();
  const elements = useElements();

  const handleEjectBattery = async (
    event: React.SyntheticEvent<HTMLButtonElement>,
  ) => {
    try {
      event.preventDefault();
      setIsRequesting(true);
      if (!stripe || !elements) {
        return;
      }
      const cardElement = elements.getElement('cardNumber');
      if (!cardElement) return;
      const stripePayment = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });
      if (
        stripePayment.error &&
        stripePayment.error.code !== 'setup_intent_unexpected_state'
      ) {
        setError(
          lt(
            `stripeError.${stripePayment.error.code}`,
            stripePayment.error.message ?? '',
          ) ||
            lt(
              'text.hooks.useEjectBattery.error',
              'An unknown error occurred when setup credit card intent!',
            ),
        );
        return;
      }
      let paymentMethodId = null;

      if (
        stripePayment.error &&
        stripePayment.error.code === 'setup_intent_unexpected_state'
      ) {
        // this card already setup intent success
        paymentMethodId = stripePayment.error.setup_intent
          ?.payment_method as string;
      } else {
        paymentMethodId = stripePayment.paymentMethod?.id as string;
      }

      setError(null);

      try {
        // Submit the payment method to your server
        if (!paymentMethod) return;

        const paymentInfo = {
          paymentMethod: {
            id: paymentMethodId,
            method: paymentMethod,
          },
        };
        //EJECT BATTERY AFTER SAVE PAYMENT INFO SUCCESS
        savePaymentInfoMutation.mutate(paymentInfo, {
          onSuccess: () => {
            const stationSerialNumber = searchParams.get('box');
            if (stationSerialNumber) {
              requestBatteryMutation.mutate(
                {
                  paymentMethod: paymentInfo.paymentMethod,
                  stationSerialNumber: stationSerialNumber,
                },
                {
                  onSuccess: (res) => {
                    //delay 5s then refetch
                    setIsFetchingRental(true);
                    setTimeout(() => {
                      saveRentalId(res.data?.rentalId);
                      setIsFetchingRental(false);
                    }, 5000);
                  },
                },
              );
            }
          },
        });
      } catch (err) {
        toast.error((err as Error).message);
      } finally {
        setIsRequesting(false);
      }
    } catch (err) {
      toast.error((err as Error).message);
    } finally {
      setIsRequesting(false);
    }
  };

  return {
    error,
    handleEjectBattery,
    isRequesting: !!(
      requestBatteryMutation.isLoading ||
      savePaymentInfoMutation.isLoading ||
      rentalQuery?.isFetching ||
      isFetchingRental ||
      isRequesting
    ),
  };
};
