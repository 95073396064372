import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

import useSyncMutation from './useSyncMutation';

import { useLanguageContext } from '~providers';
import { postRequest } from '~services';
import { PaymentInfoRequest } from '~types';

export const useMutationSavePaymentInfo = () => {
  const { lt } = useLanguageContext();

  return useSyncMutation(
    (paymentInfo: PaymentInfoRequest) => {
      return postRequest({
        path: 'api/payment/update-payment-method',
        data: paymentInfo,
      });
    },
    {
      onError(error) {
        if (error instanceof AxiosError) {
          let message = '';

          if (error.response?.data?.message.includes('; code:')) {
            const errorResponseMessage = error.response?.data
              ?.message as string;

            // Extract the message before '; code:'
            message = errorResponseMessage
              .slice(0, errorResponseMessage.indexOf('; code:'))
              .trim();

            // Extract the code after '; code:'
            const codeStartIndex =
              errorResponseMessage.indexOf('; code:') + '; code:'.length;
            const codeEndIndex = errorResponseMessage.indexOf(
              ';',
              codeStartIndex,
            );
            const code = errorResponseMessage
              .slice(
                codeStartIndex,
                codeEndIndex !== -1 ? codeEndIndex : undefined,
              )
              .trim();

            message = lt(`stripeError.${code}`, message);
          } else {
            message =
              error?.response?.data?.message ??
              lt(
                'text.errorRequestBattery',
                'Somethings went wrong when request battery',
              );
          }
          toast.error(message);
        } else {
          toast.error(
            lt(
              'text.hooks.useQueryGetRentalInfo.error',
              'Somethings went wrong when save payment info',
            ),
          );
        }
      },
      onSuccess() {
        toast.success(
          lt(
            'text.hooks.useQueryGetRentalInfo.success',
            'Save payment info success!',
          ),
        );
      },
    },
  );
};
