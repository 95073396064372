import React from 'react';
import { TypographyProps } from '@mui/material';

import {
  ComplexLocalizationProps,
  ComplexLocalizationTypography,
} from './ComplexLocalizationTypography';
import { SimpleLocalizationTypography } from './SimpleLocalizationTypography';

export type LocalizationTypographyProps = TypographyProps &
  ComplexLocalizationProps;

export const LocalizationTypography: React.FC<LocalizationTypographyProps> = ({
  components,
  ...rest
}) => {
  if (components) {
    return <ComplexLocalizationTypography components={components} {...rest} />;
  } else {
    return <SimpleLocalizationTypography {...rest} />;
  }
};
