import { Stack } from '@mui/material';
import { format } from 'date-fns';

import { LocalizationTypography } from './localization';

import { MainButton } from '~components/atoms';
import { useMainContext } from '~providers';
import { handleOpenApp } from '~utils/index';

export const EjectSuccess = () => {
  const { rentalQuery } = useMainContext();
  return (
    <Stack display="grid" sx={{ gridRowGap: 32 }}>
      <Stack
        p={4}
        bgcolor="primary.main"
        color="white"
        justifyContent="center"
        display="grid"
        textAlign="center"
        sx={{
          gridRowGap: 24,
          borderBottomRightRadius: 25,
          borderBottomLeftRadius: 25,
        }}
      >
        <Stack direction="row" justifyContent={'center'} alignItems="center">
          <img src="/images/check.svg" alt="check" />
          <LocalizationTypography
            textId="app.successPage.text.chargerEjected"
            defaultText="Charger ejected!"
            variant="h5"
            fontSize={'1.75rem'}
            fontWeight={600}
            marginLeft={2}
          />
        </Stack>
        <LocalizationTypography
          textId="app.successPage.text.rentalStarts"
          defaultText="Rental starts:"
          variant="body1"
          fontWeight={600}
        >
          {' '}
          {rentalQuery?.data?.rentedAt
            ? format(
                new Date(rentalQuery?.data?.rentedAt),
                'MMM d, yyyy h:mm a',
              )
            : ''}
        </LocalizationTypography>
        <LocalizationTypography
          textId="app.successPage.text.returnCharger"
          defaultText="Remember to take your ejected charger and kindly return to any station when you’re done."
          variant="body2"
          fontWeight={500}
        />
      </Stack>
      <Stack p={3}>
        <Stack
          sx={{ border: '1px solid #99DBFF', borderRadius: 2 }}
          bgcolor={'#F5FBFE'}
        >
          <Stack
            borderBottom={'1px solid #99DBFF'}
            bgcolor={'white'}
            sx={{
              borderTopLeftRadius: 16,
              borderTopRightRadius: 16,
              gridRowGap: 8,
            }}
            p={3}
            display="grid"
          >
            <Stack direction="row">
              <Stack>
                <img src="/images/gift-1.svg" alt="gift" width={50} />
              </Stack>
              <Stack display="grid" sx={{ gridRowGap: 8 }} marginLeft={3}>
                <LocalizationTypography
                  textId="app.successPage.text.unlockDiscount"
                  defaultText="You’ve unlocked $5 off your next rental"
                  variant="body1"
                  fontWeight={600}
                  color="primary.main"
                />
                <Stack>
                  <LocalizationTypography
                    textId="app.successPage.text.getApp"
                    defaultText="Get the chargeFUZE app today on the app store to claim this reward."
                    variant="body2"
                    fontWeight={400}
                    color="#6A737D"
                  />
                  <LocalizationTypography
                    textId="app.successPage.text.useCode"
                    defaultText="Use code:"
                    variant="body2"
                    fontWeight={400}
                    color="#6A737D"
                  >
                    {' '}
                    <span
                      style={{
                        color: '#009EFA',
                        fontWeight: 600,
                        fontSize: '0.875rem',
                      }}
                    >
                      FUZER
                    </span>
                  </LocalizationTypography>
                </Stack>
              </Stack>
            </Stack>
            <LocalizationTypography
              textId="app.successPage.text.promoValidForFirstTimeUser"
              defaultText="*This promo code is exclusively valid for first-time users. Restrictions apply.*"
              variant="body2"
              fontWeight={400}
              fontSize={'12px'}
              color="#6A737D"
              fontStyle={'italic'}
            />
          </Stack>

          <Stack p={3} display="grid" sx={{ gridRowGap: 24 }}>
            <Stack direction="row">
              <Stack>
                <img src="/images/phone.png" alt="phone" width={80} />
              </Stack>
              <Stack display="grid" sx={{ gridRowGap: 8 }} marginLeft={2}>
                <LocalizationTypography
                  textId="app.successPage.text.easyFasterRentals"
                  defaultText="Easier, faster rentals"
                  variant="body1"
                  fontWeight={600}
                  color="#0081CC"
                />
                <LocalizationTypography
                  textId="app.successPage.text.manageRentals"
                  defaultText="Manage rentals, access receipts, enter promo codes, and locate charging stations near you."
                  variant="body2"
                  fontWeight={400}
                  color="#0081CC"
                />
              </Stack>
            </Stack>
            <MainButton variant="contained" onClick={handleOpenApp}>
              <LocalizationTypography
                textId="app.successPage.button.installApp"
                defaultText="Install chargeFUZE app now"
                variant="body1"
                fontWeight={500}
              />
            </MainButton>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
