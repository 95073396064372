import { Suspense } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from 'react-router-dom';
import { Skeleton } from '@mui/material';

import { initI18n } from './i18n';

import { HomePage, RentalPage, SuccessPage } from '~components/pages';
import { WelcomeBackPage } from '~components/pages/WelcomeBackPage';
import { MainLayout } from '~components/templates';
import { LanguageProvider } from '~providers';
import { featureFlagsService } from '~services';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        path="/"
        loader={async () => {
          try {
            // Fetch flags from service rental
            await featureFlagsService.handleFetchFeatureFlags();

            // i18n initialization depends on Numerator flag
            initI18n();
          } catch (err) {
            // eslint-disable-next-line no-console
            console.error(
              'Failed to cache Numerator feature flags with reason: ',
              err,
            );
          }

          // loader requires a return
          return null;
        }}
        element={
          // Suspense is used to wait for i18n initialization
          <Suspense fallback={<Skeleton />}>
            <LanguageProvider>
              <MainLayout />
            </LanguageProvider>
          </Suspense>
        }
      >
        <Route index element={<HomePage />} />
        <Route path="/fuzebox" element={<RentalPage />} />
        <Route path="/success" element={<SuccessPage />} />
        <Route path="/welcome-back" element={<WelcomeBackPage />} />
      </Route>
    </>,
  ),
);
