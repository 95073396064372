import React, { useMemo } from 'react';
import { useMount } from 'react-use';
import { Box, CircularProgress, Stack } from '@mui/material';

import { STEP } from '~common';
import { StepOne, StepThree, StepTwo } from '~components/organisms';
import { useMainContext } from '~providers';

export const RentalPage = () => {
  const { step, initialComplete } = useMainContext();

  const progress = useMemo(() => {
    const complete = 1; // 100%
    switch (step) {
      case STEP.INPUT_PHONE_NUMBER:
        return complete / 3;
      case STEP.INPUT_OTP:
        return (complete * 2) / 3;
      case STEP.INPUT_PAYMENT_INFO:
        return complete;
      default:
        return complete;
    }
  }, [step]);

  useMount(() => {
    window?.scrollTo({
      top: 0,
      behavior: 'smooth', // Scroll smoothly
    });
  });

  if (!initialComplete) {
    return (
      <Stack
        flex={1}
        justifyContent={'center'}
        alignItems="center"
        height={'100vh'}
      >
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <>
      <Box width="100%" height={'4px'} bgcolor={'#EBFBFF'}>
        <Box
          width={progress}
          height={'100%'}
          bgcolor={'#47D6FA'}
          borderRadius={2}
        />
      </Box>

      <Stack p={3} display="grid" sx={{ gridRowGap: 40 }}>
        {step === STEP.INPUT_PHONE_NUMBER && <StepOne />}
        {step === STEP.INPUT_OTP && <StepTwo />}
        {step === STEP.INPUT_PAYMENT_INFO && <StepThree />}
      </Stack>
    </>
  );
};
