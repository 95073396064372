/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from 'react-i18next';
import { Typography, TypographyProps } from '@mui/material';

export type LocalizationProps = {
  disableParentTypography?: boolean; // parent Typography component is not being utilized
  textId?: string;
  defaultText: string;
  textValues?: Record<string, any> | undefined;
};
/*
 * The purpose of this function is to display SIMPLE localization text.
 */
export const SimpleLocalizationTypography: React.FC<
  TypographyProps & LocalizationProps
> = ({
  textId,
  defaultText,
  textValues,
  disableParentTypography,
  children,
  ...typographyProps
}) => {
  // ready is to determine whether the translations have fully loaded or not
  const { t, ready, i18n } = useTranslation();
  const localized = ready && textId && i18n.exists(textId);
  const renderText = () => (
    <>
      {localized ? t(textId, textValues) : defaultText}
      {children}
    </>
  );
  return disableParentTypography ? (
    renderText()
  ) : (
    <Typography {...typographyProps}>{renderText()}</Typography>
  );
};
