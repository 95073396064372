import { useEffect } from 'react';

import { TRACKING_PAGES } from '~common';
import { EjectSuccess } from '~components/organisms';
import { useEventTrackingContext } from '~providers';

export const SuccessPage = () => {
  const { recordPageVisited } = useEventTrackingContext();

  useEffect(() => {
    recordPageVisited(TRACKING_PAGES.SUCCESS);
  }, [recordPageVisited]);

  return <EjectSuccess />;
};
