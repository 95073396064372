import { AxiosResponse } from 'axios';

import { postAuthRequest } from './auth.service';

import { API_ENDPOINT_KEYS, SERVICE_AUTH_ENDPOINTS } from '~common';
import { AUTH_API_CLIENT_ID } from '~configs';
import { LoggedInUserInfo, UserAuthorization, UserInfo } from '~types';

export const parseJwt = (token: string) => {
  try {
    if (!token) return null;
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

const ONE_DAY_IN_SECONDS = 86400;
const REFRESH_TOKEN_KEY = 'cf-webapp-refresh_token';

export const inMemoryJWTManager = () => {
  let logoutEventName = 'cf-webapp-logout';
  let inMemoryJWT: string | null = null;
  let jwtExpiresAt: number | null = null;
  let userInfo: UserInfo | LoggedInUserInfo | null = null;
  let isRefreshingToken = false;
  let refreshPromise: Promise<string | null> | null = null;

  if (typeof window !== 'undefined') {
    window.addEventListener('storage', (event) => {
      if (event.key === logoutEventName) {
        inMemoryJWT = null;
      }
    });
  }

  const setRefreshToken = (token: string) => {
    localStorage.setItem(REFRESH_TOKEN_KEY, token);
  };

  // The method makes a call to the refresh-token endpoint
  // If there is a valid cookie, the endpoint will return a fresh jwt.
  const getNewAccessToken = () => {
    // always get refresh token from local storage for persistence between multiple tabs
    const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY) ?? '';

    // refresh token doesn't exist in local storage, return
    if (!refreshToken) {
      return Promise.reject("Log out because we can't renew the token.");
    }
    const request = postAuthRequest({
      path: SERVICE_AUTH_ENDPOINTS[API_ENDPOINT_KEYS.REFRESH_TOKEN],
      data: {
        refresh_token: refreshToken,
        grant_type: 'refresh_token',
        client_id: AUTH_API_CLIENT_ID,
      },
      ignoreAuthHeader: true,
    });
    return request
      .then((response: AxiosResponse) => {
        if (response.status !== 200) {
          eraseToken();
          // eslint-disable-next-line no-console
          console.log('Failed to renew the jwt from the refresh token.');
          return { refreshToken: null, accessToken: null };
        }
        return response.data;
      })
      .then((userInfo: UserAuthorization) => {
        if (userInfo.accessToken) {
          setToken(userInfo.accessToken, userInfo.expiresIn);
        }
        if (userInfo.refreshToken) {
          setRefreshToken(userInfo.refreshToken);
        }
        return Promise.resolve(userInfo);
      })
      .catch(() => {
        eraseToken();
        return Promise.reject("Log out because we can't renew the token.");
      });
  };

  // The method returns the current token if it's not expired,
  // otherwise it will refresh the token and return the new one.
  const getValidToken = (): Promise<string | null> => {
    if (jwtExpiresAt && jwtExpiresAt < Date.now()) {
      // Prevent multiple token refresh attempts by checking and
      // setting isRefreshingToken to true
      if (!isRefreshingToken) {
        isRefreshingToken = true;
        refreshPromise = getNewAccessToken().then(() => {
          isRefreshingToken = false;
          refreshPromise = null;
          return inMemoryJWT;
        });
      }

      // refreshPromise ensures that the token being refreshed is
      // consistently returned across concurrent calls
      if (refreshPromise) {
        return refreshPromise;
      }
    }
    return Promise.resolve(inMemoryJWT);
  };

  // This getter is retained to maintain compatibility
  // with useQueryGetPaymentInfo and useQueryGetPreAuthAmount.
  const getCurrentToken = () => inMemoryJWT;

  const setToken = (token: string, expiresIn: number = ONE_DAY_IN_SECONDS) => {
    inMemoryJWT = token;
    jwtExpiresAt = Date.now() + Math.max(expiresIn - 10, 0) * 1000; // expiry time minus 10 seconds
  };

  const setUserInfo = (info: UserInfo | LoggedInUserInfo) => {
    userInfo = info;
  };

  const eraseToken = () => {
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    inMemoryJWT = null;
    jwtExpiresAt = null;
    isRefreshingToken = false;
    refreshPromise = null;
    userInfo = null;
    window.localStorage.setItem(logoutEventName, String(Date.now()));
  };

  const setLogoutEventName = (name: string) => (logoutEventName = name);

  return {
    userInfo,
    eraseToken,
    getValidToken,
    getCurrentToken,
    setToken,
    setLogoutEventName,
    setRefreshToken,
    setUserInfo,
    getNewAccessToken,
  };
};

export const inMemoryJWTService = inMemoryJWTManager();
