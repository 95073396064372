import { useQuery } from '@tanstack/react-query';

import { QUERY_KEY } from '~common';
import { getRequest } from '~services';
import { inMemoryJWTService } from '~services/jwt.service';

export const useQueryGetPaymentInfo = () => {
  return useQuery(
    [QUERY_KEY.GET_PAYMENT_INFO],
    () => {
      return getRequest({
        path: 'api/payment/info',
      }).then((res) => res);
    },
    {
      enabled: !!inMemoryJWTService.getCurrentToken(),
    },
  );
};
