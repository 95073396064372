import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Radio,
  Stack,
} from '@mui/material';
import {
  PaymentRequestButtonElement,
  useStripe,
} from '@stripe/react-stripe-js';
import { PaymentRequest } from '@stripe/stripe-js';

import { CreditCardPaymentForm } from './CreditCardPaymentForm';
import { LocalizationTypography } from './localization';

import { PAYMENT_METHOD, TRACKING_BUTTON } from '~common';
import { MainButton } from '~components/atoms';
import { useEjectBattery, useMutationRequestBattery } from '~hooks';
import { useEventTrackingContext, useMainContext } from '~providers';

export const PaymentForm = () => {
  const {
    paymentMethod,
    onChangePaymentMethod,
    isEnabledEjectBtn,
    stationSerialNumber,
    rentalQuery,
    saveRentalId,
  } = useMainContext();
  const [searchParams] = useSearchParams();

  const { error, isRequesting, handleEjectBattery } = useEjectBattery();
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(
    null,
  );
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isFetchingRental, setIsFetchingRental] = useState(false);
  const stripe = useStripe();
  const requestBatteryMutation = useMutationRequestBattery();
  const { recordBtnClicked } = useEventTrackingContext();

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Configure Apple Pay',
          amount: 0,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });
      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        if (result?.applePay) {
          setPaymentRequest(pr);
        }
      });
    }
  }, [stripe, stationSerialNumber]);

  useEffect(() => {
    if (paymentRequest && !isSubscribed) {
      paymentRequest.on('paymentmethod', async (event) => {
        try {
          const paymentInfo = {
            paymentMethod: {
              id: event.paymentMethod.id,
              method: PAYMENT_METHOD.APPLE_PAY,
            },
          };
          if (stationSerialNumber) {
            requestBatteryMutation.mutate(
              {
                stationSerialNumber: stationSerialNumber,
                paymentMethod: paymentInfo.paymentMethod,
              },
              {
                onSuccess: (res) => {
                  saveRentalId(res.data?.rentalId);
                  //delay 5s then refetch
                  setIsFetchingRental(true);
                  setTimeout(() => {
                    rentalQuery?.refetch();
                    setIsFetchingRental(false);
                  }, 5000);
                },
              },
            );
          }

          event.complete('success');
        } catch (err) {
          event.complete('fail');
        }
      });

      setIsSubscribed(true);
    }
  }, [
    paymentRequest,
    isSubscribed,
    stationSerialNumber,
    rentalQuery,
    saveRentalId,
    requestBatteryMutation,
  ]);

  const ejectBattery = useCallback(
    (event: React.SyntheticEvent<HTMLButtonElement>) => {
      handleEjectBattery(event).then(() => true);
      recordBtnClicked(TRACKING_BUTTON.EJECT_BATTERY, {
        paymentMethod: paymentMethod,
      });
    },
    [handleEjectBattery, paymentMethod, recordBtnClicked],
  );

  return (
    <>
      <Stack sx={{ border: '1px solid #DBDBDB', borderRadius: 1 }}>
        <Accordion
          disableGutters
          elevation={0}
          expanded={paymentMethod === PAYMENT_METHOD.CREDIT_CARD}
          onChange={onChangePaymentMethod(PAYMENT_METHOD.CREDIT_CARD)}
        >
          <AccordionSummary
            expandIcon={
              <IconButton size="small">
                <Radio checked={paymentMethod === PAYMENT_METHOD.CREDIT_CARD} />
              </IconButton>
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Stack direction="row" gap={2} alignItems={'center'}>
              <Stack width={'35px'} alignItems="center">
                <img
                  src="/images/credit_card.svg"
                  alt="Credit Card"
                  width={19}
                />
              </Stack>
              <LocalizationTypography
                variant="body1"
                fontWeight={600}
                textId="app.rentalPage.stepThree.paymentForm.creditCardTitle"
                defaultText="Add credit or debit card"
              />
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <CreditCardPaymentForm error={error} processing={isRequesting} />
          </AccordionDetails>
        </Accordion>
        {paymentRequest && (
          <Accordion
            elevation={0}
            expanded={paymentMethod === PAYMENT_METHOD.APPLE_PAY}
            onChange={onChangePaymentMethod(PAYMENT_METHOD.APPLE_PAY)}
          >
            <AccordionSummary
              expandIcon={
                <IconButton size="small">
                  <Radio checked={paymentMethod === PAYMENT_METHOD.APPLE_PAY} />
                </IconButton>
              }
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Stack direction="row" gap={2} alignItems={'center'}>
                <img src="/images/ApplePay.svg" alt="Apple Pay" width={35} />
                <LocalizationTypography
                  variant="body1"
                  fontWeight={600}
                  textId="app.rentalPage.stepThree.paymentForm.applePayTitle"
                  defaultText="Apple Pay"
                />
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <PaymentRequestButtonElement options={{ paymentRequest }} />
            </AccordionDetails>
          </Accordion>
        )}
      </Stack>
      <Stack
        direction="row"
        sx={{ border: '1px solid #DBDBDB', borderRadius: 2 }}
        p={2}
      >
        <Stack mt={0.5} marginRight={2}>
          <img src="/images/info.svg" alt="info" width={16} />
        </Stack>
        <LocalizationTypography
          variant="body2"
          color="#6A737D"
          textId="app.rentalPage.stepThree.paymentForm.returnChargerNotice"
          defaultText="Kindly return charger to any station when finished to avoid additional charge."
        />
      </Stack>
      <MainButton
        variant="contained"
        disabled={
          !searchParams.get('box') || !isEnabledEjectBtn || isRequesting
        }
        onClick={ejectBattery}
        isLoading={
          isRequesting ||
          requestBatteryMutation.isLoading ||
          rentalQuery?.isFetching ||
          isFetchingRental
        }
      >
        <LocalizationTypography
          variant="body1"
          fontWeight={500}
          textId="app.rentalPage.stepThree.paymentForm.buttonEjectCharger"
          defaultText="Eject Charger"
        />
      </MainButton>
    </>
  );
};
